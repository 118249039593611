<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="type">
          <el-select
              v-model="query.type"
              clearable
              placeholder="请选择设备类型"
          >
            <el-option label="视频桩" value="0"></el-option>
            <el-option label="地磁" value="1"></el-option>
            <el-option label="巡检车" value="2"></el-option>
            <el-option label="PDA" value="3"></el-option>
            <el-option label="高点" value="4"></el-option>
            <el-option label="相机" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="onLine">
          <el-select
              v-model="query.onLine"
              clearable
              placeholder="请选择设备状态"
          >
            <el-option label="在线" value="0"></el-option>
            <el-option label="离线" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="parkingLotName">
          <el-input
              v-model="query.parkingLotName"
              clearable
              placeholder="车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content" style="margin-left: 0;">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ pagination.totalSize || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">视频桩：</div>
          <div class="text">{{ state.videoStakeCount || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">地磁：</div>
          <div class="text">{{ state.magnetismCount || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">高位视频：</div>
          <div class="text">{{ state.highPointCount || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">道闸相机：</div>
          <div class="text">{{ state.barrierGateCount || '0' }}</div>
        </div>
        <div class="content-item">
          <div class="title">巡检车：</div>
          <div class="text">{{ state.inspectionCarCount || '0' }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div class="row_new padding-bottom19">
        <el-button type="primary" size="small" @click="toAdd">新增</el-button>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="设备类型" prop="type" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0">视频桩</span>
            <span v-if="scope.row.type === 1">地磁</span>
            <span v-if="scope.row.type === 2">巡检车</span>
            <span v-if="scope.row.type === 3">PDA</span>
            <span v-if="scope.row.type === 4">高点</span>
            <span v-if="scope.row.type === 5">相机</span>
          </template>
        </el-table-column>
        <el-table-column label="车场名称" prop="parkingLotName" min-width="240">
        </el-table-column>
        <el-table-column label="泊位号" prop="parkingSpaceCode" width="150">
        </el-table-column>
        <el-table-column label="设备状态" prop="status" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0">正常</span>
          </template>
        </el-table-column>
        <el-table-column label="设备SN" prop="sn" width="180">
        </el-table-column>
        <el-table-column label="设备编号" prop="code" width="150">
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180">
        </el-table-column>
        <el-table-column label="车场状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0">正常</span>
            <span v-if="scope.row.status === 1">禁用</span>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="100"
        >
          <template slot-scope="scope">
            <el-button
                size="small common-text"
                type="text"
                @click="doEdit(scope.row)"
            >编辑
            </el-button
            >
            <el-button
                style="color: #EA5504!important;"
                size="small common-text"
                type="text"
                @click="doDelete(scope.row.id)"
            >删除
            </el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
        :visible.sync="passageDialogVisible"
        append-to-body
        class="common-dialog"
        title="通道配置"
        width="900px"
    >
      <Passage ref="passage" @success="onSuccess"/>
      <!-- :entryNumber="currentRow.entryNumber"
        :outNumber="currentRow.exportNumber" -->
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  deleteDevice,
  getDeviceList,
} from "@/api/device";
import {getRegionList} from "@/api/user";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
// import Settlement from "@/components/Settlement";
import Passage from "./components/passage.vue";
import {doExport} from "@/api/common";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  name: "parking",
  mixins: [BaseMixin, Permission],
  components: {
    // Settlement,
    Passage,
  },
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      query: {
        // 停车场类型
        type: "",
        // 停车场状态
        status: "",
        onLine: '',
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        name: "",
        // 0-运营 1-机关事业单位 2-联网
        managementCompany: "", //管理公司名称
        onlineParkingOrder: "", //运营模式 1运营 2仅数据
        motionType: 0,
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      state: {},
      dataList: [],
      nowStatus: "",
      passageDialogVisible: false,
      currentRow: {},
    };
  },
  mounted() {
  },
  activated() {
    // const isSave = window.sessionStorage.getItem('save_success');
    const isSave = sessionStorage.getItem("save_device_success");
    if (isSave === "2") {
      // 刷新
      this.doSearch();
    }
  },
  methods: {
    showPassage(row) {
      this.passageDialogVisible = true;
      this.currentRow = row;

      this.$nextTick(() => {
        this.$refs.passage.deviceId = row.id;
        // this.$refs.passage.getNumber();
        // this.$refs.passage.getGangtingDeviceList();
        this.$refs.passage.getParkingPassageList();
      });
    },
    onSuccess(e) {
      this.passageDialogVisible = e;
    },
    async doSearch() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              this.query.region = this.defaultRegion.length == 3 ? this.defaultRegion[2] : this.defaultRegion[1]
              const res = await getDeviceList(this.paramFormat(this.query));
              this.isLoading = false;

              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                this.dataList = returnObject.records;
                this.state = returnObject;
                this.pagination.totalSize = returnObject.total;
                this.pagination.totalPages = returnObject.pages;
                this.form.exportSize = returnObject.total;
              }
            }
          })
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      doExport(
          "/parkingLotManagementController/findParkingLotInfo",
          params,
          "停车场列表.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    doReset() {
      this.query = {
        // 停车场类型
        type: "",
        // 停车场状态
        status: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        parkingLotName: "",
        // 0-运营 1-机关事业单位 2-联网
        managementCompany: "", //管理公司名称
        onlineParkingOrder: "", //运营模式 1运营 2仅数据
        motionType: 0,
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      };
      this.doSearch();
    },
    toAdd() {
      this.$router.push({
        path: "/device_manage/add",
        query: {
          motionType: 0,
        },
      });
    },
    doEdit(item) {
      this.$router.push({
        path: "/device_manage/add",
        query: {
          deviceId: item.id,
          parkingName: item.name,
          motionType: 1,
        },
      });
    },
    async doDelete(id) {
      const res = await deleteDevice({id});
      if (res && res.code === 30 && res.result) {
        this.$message({
          message: "设备删除成功!",
          type: "success",
        });
        this.search()
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>
